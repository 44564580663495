@import './variable.less';

.bg-white {
  background-color: @white;
}

.bg-light {
  background-color: @light;
}

.bg-gray {
  background-color: @gray;
}

.bg-black {
  background-color: @black;
}

.bg-primary {
  background-color: @primary;
}

.color-white {
  color: @white;
}

.color-light {
  color: @light;
}

.color-gray {
  color: @gray;
}

.color-black {
  color: @black;
}

.color-primary {
  color: @primary;
}

.color-success {
  color: @success;
}

.color-warning {
  color: @warning;
}

.color-danger {
  color: @danger;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}

.m-6 {
  margin: 4rem;
}

.mx-6 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-6 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mt-6 {
  margin-top: 4rem;
}

.mb-6 {
  margin-bottom: 4rem;
}

.ml-6 {
  margin-left: 4rem;
}

.mr-6 {
  margin-right: 4rem;
}

.m-5-5 {
  margin: 3rem;
}

.mx-5-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-5-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mt-5-5 {
  margin-top: 3rem;
}

.mb-5-5 {
  margin-bottom: 3rem;
}

.ml-5-5 {
  margin-left: 3rem;
}

.mr-5-5 {
  margin-right: 3rem;
}

.m-5 {
  margin: 2rem;
}

.mx-5 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-5 {
  margin-top: 2rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.ml-5 {
  margin-left: 2rem;
}

.mr-5 {
  margin-right: 2rem;
}

.m-4 {
  margin: 1.5rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.m-3 {
  margin: 1rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mr-3 {
  margin-right: 1rem;
}

.m-2-5 {
  margin: 0.75rem;
}

.mx-2-5 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-2-5 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mt-2-5 {
  margin-top: 0.75rem;
}

.mb-2-5 {
  margin-bottom: 0.75rem;
}

.ml-2-5 {
  margin-left: 0.75rem;
}

.mr-2-5 {
  margin-right: 0.75rem;
}

.m-2 {
  margin: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.m-1 {
  margin: 0.3rem;
}

.mx-1 {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.my-1 {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.mt-1 {
  margin-top: 0.3rem;
}

.mb-1 {
  margin-bottom: 0.3rem;
}

.ml-1 {
  margin-left: 0.3rem;
}

.mr-1 {
  margin-right: 0.3rem;
}

.m-0-5 {
  margin: 0.15rem;
}

.mx-0-5 {
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}

.my-0-5 {
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
}

.mt-0-5 {
  margin-top: 0.15rem;
}

.mb-0-5 {
  margin-bottom: 0.15rem;
}

.ml-0-5 {
  margin-left: 0.15rem;
}

.mr-0-5 {
  margin-right: 0.15rem;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.p-6 {
  padding: 4rem;
}

.px-6 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-6 {
  padding-top: 4rem;
}

.pb-6 {
  padding-bottom: 4rem;
}

.pl-6 {
  padding-left: 4rem;
}

.pr-6 {
  padding-right: 4rem;
}

.p-5-5 {
  padding: 3rem;
}

.px-5-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-5-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pt-5-5 {
  padding-top: 3rem;
}

.pb-5-5 {
  padding-bottom: 3rem;
}

.pl-5-5 {
  padding-left: 3rem;
}

.pr-5-5 {
  padding-right: 3rem;
}

.p-5 {
  padding: 2rem;
}

.px-5 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pt-5 {
  padding-top: 2rem;
}

.pb-5 {
  padding-bottom: 2rem;
}

.pl-5 {
  padding-left: 2rem;
}

.pr-5 {
  padding-right: 2rem;
}

.p-4-5 {
  padding: 1.8rem;
}

.px-4-5 {
  padding-left: 1.8rem;
  padding-right: 1.8rem;
}

.py-4-5 {
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}

.pt-4-5 {
  padding-top: 1.8rem;
}

.pb-4-5 {
  padding-bottom: 1.8rem;
}

.pl-4-5 {
  padding-left: 1.8rem;
}

.pr-4-5 {
  padding-right: 1.8rem;
}

.p-4 {
  padding: 1.5rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.p-3-5 {
  padding: 1.3rem;
}

.px-3-5 {
  padding-left: 1.3rem;
  padding-right: 1.3rem;
}

.py-3-5 {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}

.pt-3-5 {
  padding-top: 1.3rem;
}

.pb-3-5 {
  padding-bottom: 1.3rem;
}

.pl-3-5 {
  padding-left: 1.3rem;
}

.pr-3-5 {
  padding-right: 1.3rem;
}

.p-3 {
  padding: 1rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pr-3 {
  padding-right: 1rem;
}

.p-2-5 {
  padding: 0.75rem;
}

.px-2-5 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-2-5 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pt-2-5 {
  padding-top: 0.75rem;
}

.pb-2-5 {
  padding-bottom: 0.75rem;
}

.pl-2-5 {
  padding-left: 0.75rem;
}

.pr-2-5 {
  padding-right: 0.75rem;
}

.p-2 {
  padding: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.p-1 {
  padding: 0.3rem;
}

.px-1 {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.py-1 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.pt-1 {
  padding-top: 0.3rem;
}

.pb-1 {
  padding-bottom: 0.3rem;
}

.pl-1 {
  padding-left: 0.3rem;
}

.pr-1 {
  padding-right: 0.3rem;
}

.p-0 {
  padding: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.text-max {
  font-size: 2rem; // 32
}

.text-title {
  font-size: 1.4rem; // 22.4
}

.text-plus {
  font-size: 1.25rem; // 20
}

.text-lg {
  font-size: 1.125rem; // 18
}

.text-md {
  font-size: 1rem; // 16
}

.text-des {
  font-size: 0.9375rem; // 15
}

.text-sm {
  font-size: 0.875rem; // 14
}

.text-mini {
  font-size: 0.8125rem; // 13
}

.text-min {
  font-size: 0.75rem; // 12
}

.text-zero {
  font-size: 0; // 0
}

.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.flex-column {
  .d-flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-item {
  .d-flex;
  .flex-label {
    flex: 0 0 auto;
  }
  .flex-content {
    flex: 1;
    .slim-scroll;
    &:not(.no-overflow) {
      .overflow-auto;
    }
    &.no-scroll {
      .no-scroll;
    }
    &.overflow-hidden {
      .overflow-hidden;
    }
  }
}

.flex-vertical {
  .flex-column;
  .flex-header,
  .flex-footer {
    flex: 0 0 auto;
  }
  .flex-content {
    flex: 1;
    .slim-scroll;
    &:not(.no-overflow) {
      overflow: hidden auto;
    }
    &.no-scroll {
      .no-scroll;
    }
    &.overflow-hidden {
      .overflow-hidden;
    }
  }
}

.flex-center-center {
  .d-flex;
  .align-items-center;
  .justify-content-center;
}

.cursor-pointer {
  cursor: pointer;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.absolute-0 {
  .position-absolute;
  top: 0;
  left: 0;
}

.absolute-center {
  .position-absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overflow-auto {
  overflow: auto;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.round-circle {
  border-radius: 50%;
}

.round-mx {
  border-radius: 1.8rem;
}

.round-lg {
  border-radius: 1.4rem;
}

.round-md {
  border-radius: 1rem;
}

.round-sm {
  border-radius: 0.6rem;
}

.round-mini {
  border-radius: 0.2rem;
}

.border-none {
  border: none;
}

.border-t {
  border-top: 1px solid @light;
}

.border-b {
  border-bottom: 1px solid @light;
}

.slim-scroll {
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @shade;
    background-clip: padding-box;
    border-radius: 2rem;
  }
}

.no-scroll {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.ant-btn {
  border-radius: 0.2rem;
  height: 2.6rem;
  padding: 0 0.875rem;
  font-size: 0.875rem;
  line-height: 1;
  &.ant-btn-round {
    border-radius: 2.6rem;
  }
  &.ant-btn-sm {
    height: 2rem;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    &.ant-btn-round {
      border-radius: 2rem;
    }
  }
  &.ant-btn-lg {
    height: 3rem;
    padding: 0 1rem;
    font-size: 1rem;
    &.ant-btn-round {
      border-radius: 3rem;
    }
  }
  &.ant-btn-circle {
    min-width: 2rem;
    border-radius: 50%;
  }

  &.ant-btn-default {
    color: @black !important;
    border: 1px solid rgba(26, 29, 62, 0.05) !important;
    box-shadow: none !important;
    &:hover {
      box-shadow: 0 0 0.5rem rgba(92, 112, 240, 0.3) !important;
    }
  }

  &+.ant-btn {
    margin-left: 0.875rem;
  }

  &.d-width-button {
    width: 10rem;
  }
}

.ant-input {
  .text-md;
  height: 2.8rem;
  border-radius: 0.3rem;

  &:focus {
    box-shadow: 0 0 0 0.25rem #5c70f033;
  }
}

.ant-input-number {
  .text-md;
  border-radius: 0.3rem;
  input {
    height: 2.8rem;
  }

  &:focus, &.ant-input-number-focused {
    box-shadow: 0 0 0 0.25rem #5c70f033;
  }
}

.ant-menu {
  .text-md;
  &.ant-menu-inline, &.ant-menu-vertical {
    border-right: none;
    background-color: @white;
  }
  .ant-menu-item {
    width: 100%;
    height: 3.4rem;
    line-height: 3.4rem;
    padding: 0 1.5rem !important;
    .text-md;
    .text-bold;
    margin: 0.5rem 0 !important;
    .anticon {
      min-width: 1.125rem;
      .text-lg !important;
      & + .ant-menu-title-content {
        margin-left: 0.6rem;
      }
    }
  }

  .ant-menu-submenu {
    .text-md;
    .text-bold;
    .p-0;
    .ant-menu-submenu-title {
      position: relative;
      height: 3.4rem;
      line-height: 3.4rem;
      padding: 0 1.5rem !important;
      margin: 0.5rem 0;
      .anticon {
        min-width: 1.125rem;
        .text-lg !important;
        & + .ant-menu-title-content {
          margin-left: 0.6rem;
        }
      }
      .ant-menu-submenu-arrow {
        right: 1.5rem;
        width: 0;
        height: 0;
        transform: translate(50%, calc(-50% + 2px)) scale(1.4) ;
      }
    }

    &.ant-menu-submenu-open {
      .ant-menu-submenu-title {
        .ant-menu-submenu-arrow {
          transform: translate(50%, calc(-50% - 4px)) scale(1.4);
        }
      }
    }
    .ant-menu-item {
      height: 3.2rem;
      line-height: 3.2rem;
      padding-left: 3rem !important;
      &:nth-of-type(1) {
        margin-top: 0;
      }
    }
  }

  &.ant-menu-inline-collapsed {
    width: 4.2rem;
    .ant-menu-item {
      .flex-center-center;
      .ant-menu-title-content {
        display: none !important;
      }
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        .flex-center-center;
        .ant-menu-title-content {
          display: none !important;
        }
      }
    }
  }
}

// 用于解决侧导航栏收缩时一闪而过的popup
.ant-zoom-big-leave {
  display: none !important;
}

.ant-dropdown-menu {
  padding: 0.2rem 0;
  border-radius: 0.2rem;
  box-shadow: 0 0.2rem 0.4rem -0.3rem rgba(52, 63, 133, 0.12), 0 0.5rem 1rem 0 rgba(52, 63, 133, 0.08), 0 0.6rem 2rem 0.5rem rgba(52, 63, 133, 0.05);
  .ant-dropdown-menu-item {
    padding: 0.5rem 1rem;
    .text-sm;
    line-height: normal;
    &:hover {
      background: @thin;
    }
  }
}

.anticon {
  .flex-center-center !important;
}

.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      .px-4;
      .py-2-5;
      border-radius: 0.2rem;
      box-shadow: 0 0.2rem 0.4rem -0.4rem rgb(0 0 0 / 12%), 0 0.4rem 1rem 0 rgb(0 0 0 / 8%), 0 0.5rem 2rem 0.5rem rgb(0 0 0 / 5%);
      .ant-message-custom-content {
        display: flex;
        align-items: center;
        .text-lg;
        .anticon {
          top: 0;
          .mr-2;
          .text-plus;
        }
      }
    }
  }
}

.chain-icon {
  width: 1.6rem;
  .mr-2;
}