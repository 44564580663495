@import './variable.less';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  list-style: none;
  text-decoration: none;
  user-select: none;
}

html {
  font-size: 18px;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: @black;
  font-size: 1rem;
}

ol, ul, dl, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

@media screen and (max-width: 1400px) {
  html {
    font-size: 17px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 15px;
  }
}
