.menu-list {
  .logo-box {
    width: 14.5rem;
    height: 3.8rem;
    transition: all ease-in-out 150ms;
    .logo {
      width: 4.2rem;
      img {
        height: 3.2rem;
      }
    }
    .logo_text {
      height: 2rem;
    }

    &.fold {
      width: 4.2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .menu-list {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 88;
    transition: all 200ms ease-in-out;
    &.fold {
      transform: translate(-100%, 0);
    }
  }
  .menu-bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 66;
    background-color: rgba(26, 29, 62, 0.4);
  }
}
@primary-color: #5c70f0;@error-color: #e86565;