@import '@style/variable.less';

.c-svg {
  &.hover-primary {
    &:hover {
      path {
        fill: @primary;
      }
    }
  }
  &.hover-success {
    &:hover {
      path {
        fill: @success;
      }
    }
  }
}
@primary-color: #5c70f0;@error-color: #e86565;