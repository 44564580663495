.deploy-page {
  padding: 0 4rem;
  .content-wrapper {
    width: 100%;
    max-width: 36rem;
    margin: 0 auto;
    .logo-title {
      .logo {
        width: 8rem;
        position: absolute;
        top: 0rem;
        right: 0rem;
        opacity: 0.1;
      }
    }
    .ant-input, .ant-input-number {
      width: 15rem;
    }
    input {
      text-align: right;
    }
  }
}

@media screen and (max-width: 1400px) {
  .deploy-page {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .deploy-page {
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: 414px) {
  .deploy-page {
    padding: 0 1rem;
  }
}
@primary-color: #5c70f0;@error-color: #e86565;