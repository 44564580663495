.home-page {
  padding: 0 4rem;
  .content-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 36rem;
    margin: 0 auto;
    .icon {
      width: 2.6rem;
    }
    .unit {
      width: 2.6rem;
    }
    .input-number {
      width: 15rem;
    }
  }
  .graph1 {
    position: absolute;
    top: 16rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 36rem;
    div {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-100%, -50%);
      img {
        width: 6.6rem;
        position: absolute;
        bottom: -1.4rem;
        right: 0.2rem;
        transform: rotate(-20deg);
      }
    }
  }
  .graph2 {
    width: 50rem;
    height: 50rem;
    position: absolute;
    top: -25rem;
    right: -12rem;
    img {
      width: 6.6rem;
      position: absolute;
      bottom: 8rem;
      right: 20rem;
      transform: rotate(20deg);
    }
  }
}

@media screen and (max-width: 1400px) {
  .home-page {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .home-page {
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: 414px) {
  .home-page {
    padding: 0 1rem;
    .graph1 {
      top: 2rem;
      left: 50%;
      transform: translate(-50%, 0);
      div {
        img {
          width: 3rem;
          bottom: 0rem;
          right: -8rem;
        }
      }
    }
    .graph2 {
      width: 20rem;
      height: 20rem;
      top: -10rem;
      right: -6rem;
      img {
        width: 5rem;
        bottom: 5rem;
        right: 8rem;
      }
    }
  }
}
@primary-color: #5c70f0;@error-color: #e86565;